.faq-section {
    padding: 60px 20px;
    background-color: #f7f9fc;
    text-align: center;
  }
  
  .faq-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #031233;
    margin-bottom: 40px;
  }
  
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    position: relative;
  }
  
  .faq-item:last-child {
    border-bottom: none;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    background-color: #f1f1f1;
  }
  
  .faq-question h3 {
    font-size: 1.2rem;
    color: #031233;
  }
  
  .faq-question .arrow {
    font-size: 1.5rem;
    color: #031233;
    transition: transform 0.3s ease;
  }
  
  .faq-item.active .faq-question .arrow {
    transform: rotate(360deg); /* Rotate arrow for expanded state */
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    padding: 0 20px;
    background-color: #ffffff;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .faq-answer p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  .faq-item.active .faq-answer {
    max-height: 500px; /* Adjust as needed based on content */
    padding: 15px 20px; /* Padding for expanded state */
  }
  
  /* Smooth transition for height */
  @keyframes expand {
    from {
      max-height: 0;
    }
    to {
      max-height: 500px; /* Adjust as needed */
    }
  }
  