/* Footer Container */
.footer {
  background-color: #f8f8f8; /* Light grey background */
  color: #333333; /* Dark text color */
  padding: 40px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* Footer Links */
.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-links a {
  color: #333333; /* Dark text color */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, transform 0.3s;
}

.footer-links a:hover {
  color: #b40717; /* Accent color for hover effect */
  transform: scale(1.05); /* Slight zoom effect */
}

/* Contact Details */
.footer-contact {
  margin-bottom: 20px;
  font-size: 14px;
  opacity: 0.8;
}

.footer-contact p, 
.footer-contact p a {
  margin: 5px 0;
  text-decoration: none;
  color: black;
}

/* Footer Text */
.footer-text {
  font-size: 14px;
  margin: 0;
  opacity: 0.7;
}

/* Footer Animation */
.footer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px; /* Thickness of the line */
  background-color: #b40717; /* Line color matching the hover effect */
  transform: translateX(-100%);
  animation: slideIn 3s linear infinite;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    gap: 10px;
  }

  .footer-contact {
    font-size: 13px;
  }
}
