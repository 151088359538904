/* Navbar Container */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Logo and Company Name */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 35px;
  margin-right: 10px;
}

.company-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #b40717;
  text-decoration: none;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 30px;
}

.nav-links a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 15px;
  transition: color 0.3s, transform 0.3s;
}

.nav-links a:hover {
  color: #b40717;
  transform: scale(1.05);
}

/* Mobile Menu (Hamburger Icon) */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.menu-toggle.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #000000;
  margin: 3px 0;
  transition: 0.3s;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 20;
    padding: 20px 0;
    text-align: center;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.5s ease, opacity 0.5s ease;
  }

  .nav-links.open {
    display: flex;
    transform: translateY(0);
    opacity: 1;
  }

  .menu-toggle {
    display: flex;
  }

  .company-name {
    display: none;
  }

  .navbar{
    justify-content: space-around;
  }
}
