/* Services.css */
.services-section {
    padding: 50px;
    background-color: white;
    text-align: center;
  }
  
  .services-heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .services-description {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 40px;
  }
  
  /* Grid layout for 3 cards per row */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 30px;
  }
  
  .service-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .service-title {
    font-size: 1.5rem;
    color: #333;
    margin: 20px 0 10px;
  }
  
  .service-description {
    font-size: 1rem;
    color: #666;
    padding: 0 20px 20px;
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 1024px) {
    .services-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    }
  }
  
  @media (min-width: 2550px) {
    .services-section {
      width: 1600px;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr; /* 1 card per row */
    }
  }
  